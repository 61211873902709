@media (min-width: 280px) and (max-width: 300px) {
  .videoFrame {
    width: 258px;
    height: 147px;
  }
}

@media (min-width: 370px) and (max-width: 380px) {
  .videoFrame {
    width: 270px;
    height: 154px;
  }
}
@media (min-width: 385px) and (max-width: 410px) {
  .videoFrame {
    width: 345px;
    height: 196px;
  }
}

@media (min-width: 350px) and (max-width: 365px) {
  .videoFrame {
    width: 272px;
    height: 155px;
  }
}
@media (min-width: 375px) and (max-height: 835px) {
  #chat-container {
    margin-bottom: 75px;
  }
}
@media (min-width: 1200px) and (max-height: 840px) {
  #chat-container {
    margin-bottom: 10px;
  }
}
/* @media (width: 1280px) and (height: 840px) {
  #chat-container {
    margin-bottom: 0px;
  }
} */
@media (width: 280px) and (height: 653px) {
  #chat-container {
    margin-bottom: 18vh;
  }
}
@media (width: 1024px) and (height: 600px) {
  #chat-container {
    margin-bottom: 27vh;
  }
}
@media (width: 360px) and (height: 740px) {
  #chat-container {
    margin-bottom: 86px;
  }
}
/* @media (min-width: 1024px) and (max-width: 1030px) {
  #chat-container {
    margin-bottom: 4vh;
  }
} */

@media (min-width: 600px) and (max-width: 899px) {
  #chat-container {
    margin-bottom: 6vh;
  }
}

@media (min-width: 900px) and (max-width: 1023px) {
  #chat-container {
    margin-bottom: 8vh;
  }
}

@media (min-width: 1030px) and (max-width: 1199px) {
  #chat-container {
    margin-bottom: 8vh;
  }
}

.scrollbar-side::-webkit-scrollbar {
  width: 8px;
}
.scrollbar-side::-webkit-scrollbar-track {
  background: #6f7c97;
  border-radius: 10px;
}
.scrollbar-side {
  z-index: 9999;
}
.scrollbar-side::-webkit-scrollbar-thumb {
  background: #1d2535;
  border-radius: 10px;
}
.company-website-btn a:hover {
  border-radius: 8px;
  border: 1px solid #5761fe;
  background: #5761fe;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
}
.company-website-btn a svg {
  margin-left: 5px;
}
.company-website-btn a {
  border-radius: 8px;
  padding-right: 30px;
  width: 100%;
  border: 1px solid #004eeb;
  background: #004eeb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: 600;
  line-height: 20px;
  height: 40px;
  line-height: 42px;
  padding-top: 2px;
  padding-left: 30px;
}

.loader-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: #0003;
  width: 100%;
  height: 100%;
}

.spinLoder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glass-background-image {
  background: no-repeat center center fixed;
  background-size: cover;
  display: block;
  left: -5px;
  top: -5px;
  bottom: -24px;
  position: fixed;
  right: -5px;
  z-index: -2;
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin: -24px;
}

@media (min-width: 1200px) {
  .ravinder {
    -webkit-flex-basis: 33.333333% !important;
    -ms-flex-preferred-size: 33.333333% !important;
    flex-basis: 33.333333% !important;
    max-width: 33.333333% !important;
  }
}

@media (max-width: 600px) {
  .custom-tab-pricing .MuiTabs-flexContainer {
    flex-flow: wrap;
  }
  .custom-tab-pricing button.MuiTab-root {
    width: 100% !important;
    flex-basis: 100% !important;
  }
}
.edit-quill .ql-editor{
  overflow-y: inherit  !important;
  overflow: inherit !important;
}

.edit-quill .ql-container.ql-snow{
  border: none !important;
}
.edit-quill .ql-snow .ql-tooltip a.ql-preview{
  pointer-events: none !important;
}


.focus-removed .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  box-shadow: none !important;
}
.focus-removed .MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  box-shadow: none !important;
}




/* .ql-editor img {
  user-select: none !important;
  pointer-events: none !important;
} */